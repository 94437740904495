import React from "react"
import { PropTypes } from "prop-types"
import BackgroundImage from "gatsby-background-image"
import styled from "styled-components"
import tw from "tailwind.macro"
import classNames from "classnames"

import Farmer from "../../images/farmer-2.jpg"
import PetalMask from "../../images/petal-mask.svg"

const PetalBannerSection = styled.section`
  ${tw`overflow-hidden mx-auto md:pb-0`}
  max-width: 1920px;
  padding-bottom: 115px;
`

const ColContainer = styled.div`
  ${tw`relative`}

  .petal-background {
    background-image: url(${Farmer});
    ${tw`bg-no-repeat bg-cover bg-center`}
  }

  .gatsby-background {
    :after,
    :before {
      height: 99% !important;
      top: 2px !important;

      @media (min-width: 1920px) {
        width: 99% !important;
      }
    }
  }

  img {
    box-shadow: inset 0px -1px 0px 0px #fff;
    border: 1px solid #fff;
    max-width: 175%;
    margin-left: -38%;

    @media (min-width: 768px) {
      max-width: 130%;
      margin-left: -12%;
    }

    @media (min-width: 1024px) {
      max-width: 120%;
      margin-left: -39px;
    }

    @media (min-width: 1280px) {
      max-width: 100%;
      margin-left: 0;
      box-shadow: -4px 10px 20px 20px white;
      border: 2px;
    }
  }

  h2 {
    ${tw`font-fb bg-white text-xl text-primary lg:w-7/12`}
    padding: 35px 25px 29px 59px;
    border-radius: 170px 0px 0px 170px;
    width: 85%;
    transform: translate(20%, -53%);
    letter-spacing: -0.4px;
    ${tw`absolute`}

    @media (min-width: 768px) {
      ${tw`right-0`}
      top: 56%;
      width: 69%;
      transform: none;
      padding: 30px 75px 33px 50px;
    }

    @media (min-width: 1024px) {
      width: 50%;
      top: 63%;
      padding: 30px 75px 25px 50px;
    }

    @media (min-width: 1280px) {
      top: 55%;
      letter-spacing: -0.72px;
    }

    @media (min-width: 1440px) {
      font-size: 36px;
      line-height: 60px;
      padding: 40px 75px 50px 115px;
    }
  }
`

const PetalBanner = ({ petalText, className, petalImage }) => {
  return (
    <PetalBannerSection
      className={classNames("section-gap-sm", "xl:section-gap-xl", className)}
    >
      <ColContainer>
        {petalImage ? (
          <BackgroundImage
            tag="div"
            className="gatsby-background"
            fluid={petalImage}
          >
            <img src={PetalMask} alt="petal mask" />
          </BackgroundImage>
        ) : (
          <div className="petal-background">
            <img src={PetalMask} alt="petal mask" />
          </div>
        )}
        <h2 className="xs:h2-sm">{petalText}</h2>
      </ColContainer>
    </PetalBannerSection>
  )
}

PetalBanner.propTypes = {
  className: PropTypes.string,
  petalText: PropTypes.string.isRequired,
}

export default PetalBanner
