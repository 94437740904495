import React from "react"
import SEO from "components/seo"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SubBanner from "../components/SubBanner"
import TitleIconColumns from "../components/TitleIconColumns"
import TwoColumn from "../components/TwoColumn"
import Tabs from "../components/Tabs"
import PetalBanner from "../components/PetalBanner"
import Testimonials from "../components/Testimonials"
import CTA from "../components/CTA"

export const query = graphql`
  query Supplier {
    page: allWpPage(filter: { slug: { eq: "supplier" } }) {
      edges {
        node {
          nodeType
          pageTitle: title
          acfSubPageBanner {
            subPageBannerImage {
              localFile {
                childImageSharp {
                  fluid(quality: 90, maxWidth: 1920) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            sloganText: subPageBannerSloganText
            sloganTitle: subPageBannerSloganTitle
          }
          acfCta {
            ctaShow
            ctaText
            ctaLink1 {
              target
              title
              url
            }
            ctaLink2 {
              target
              title
              url
            }
          }
          acfTestimonials {
            testimonialShow
            testimonialCard {
              testimonialAuthorCompany
              testimonialAuthorName
              testimonialContent
              testimonialImage {
                localFile {
                  childImageSharp {
                    fluid(quality: 90, maxWidth: 325) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
          }
          acfSellers {
            sellersPetalImageContent
            sellersPetalImage {
              localFile {
                childImageSharp {
                  fluid(quality: 90, maxWidth: 1920) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            sellersTitle
            sellersTitleTagline
            sellersOptions {
              sellersText
              sellersLink
              sellersIcon {
                localFile {
                  publicURL
                }
              }
            }
            tabTitleTagline
            tabTitle
            tabDescription
            tabSection {
              tabContent
              tabTitle
              tabButton {
                target
                title
                url
              }
              tabImage {
                localFile {
                  childImageSharp {
                    fluid(quality: 90) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
            sellersTwoColumnRow {
              description: sellersTwoColDescriptionContent
              mainTitle: sellersTwoColMainTitle
              subTitle: sellersTwoColSubTitle
              buttonTextLink: sellersButtonTextAndLink {
                target
                title
                url
              }
              twoColImage: sellersTwoColImage {
                localFile {
                  childImageSharp {
                    fluid(quality: 90, maxWidth: 680) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
          }
          ...SEO
        }
      }
    }
  }
`

const Supplier = ({ location, data }) => {
  const {
    seo,
    acfSubPageBanner,
    acfCta,
    acfSellers,
    acfTestimonials,
  } = data.page.edges[0].node

  return (
    <Layout path={location.pathname}>
      <SEO data={seo} />

      <SubBanner
        mainTitle={acfSubPageBanner.sloganTitle}
        subTitle={acfSubPageBanner.sloganText}
        image={acfSubPageBanner?.subPageBannerImage?.localFile?.childImageSharp?.fluid}
      />

      <TitleIconColumns
        subTitle={acfSellers.sellersTitleTagline}
        mainTitle={acfSellers.sellersTitle}
        items={acfSellers.sellersOptions}
        iconFieldName="sellersIcon"
        textFieldName="sellersText"
        fieldLink="sellersLink"
      />

      <Tabs
        subTitle={acfSellers.tabTitleTagline}
        mainTitle={acfSellers.tabTitle}
        description={acfSellers.tabDescription}
        data={acfSellers.tabSection}
      />

      <TwoColumn data={acfSellers.sellersTwoColumnRow} />

      <PetalBanner
        petalText={acfSellers.sellersPetalImageContent}
        petalImage={
          acfSellers.sellersPetalImage.localFile.childImageSharp.fluid
        }
      />

      {acfTestimonials.testimonialShow && (
        <Testimonials data={acfTestimonials.testimonialCard} />
      )}

      {acfCta.ctaShow && (
        <CTA
          text={acfCta.ctaText}
          buttonOne={acfCta.ctaLink1}
          buttonTwo={acfCta.ctaLink2}
        />
      )}
    </Layout>
  )
}

export default Supplier
