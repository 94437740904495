import React from "react"
import { PropTypes } from "prop-types"
import styled from "styled-components"
import tw from "tailwind.macro"

import Leafs from "../../images/3-leafs.svg"

const SectionTitlesSection = styled.div`
  ${tw`mx-auto`}
  display: ${props => (props.hideForSmall ? "none" : "block")};
  max-width: ${props => (props.maxWidth ? "768px" : "unset")};
  text-align: ${props => (props.centerTitles ? "center" : "left")};

  @media (min-width: 768px) and (max-width: 1280px) {
    display: ${props => (props.hideForTab ? "none" : "block")};
    text-align: ${props => (props.centerTitlesTab ? "center" : "left")};
  }

  @media (min-width: 1280px) {
    display: ${props => (props.hideForLarge ? "none" : "block")};
    
  }

  h5 {
    ${tw`font-fb text-xs uppercase mb-8 text-primary`}
    letter-spacing: 3.6px;

    :before {
      content: "";
      background-image: url(${Leafs});
      ${tw`bg-contain bg-no-repeat w-12 h-12 block mb-4`}
      margin-left: ${props => (props.centerTitles ? "auto" : "0")};
      margin-right: ${props => (props.centerTitles ? "auto" : "0")};


      @media (min-width: 768px) and (max-width: 1280px) {
        margin-left: ${props => (props.centerTitlesTab ? "auto" : "0")};
        margin-right: ${props => (props.centerTitlesTab ? "auto" : "0")};
      }

      @media (min-width: 1280px) {
        margin-left: ${props => (props.centerTitles ? "auto" : "0")};
        margin-right: ${props => (props.centerTitles ? "auto" : "0")};
      }
    }
  }

  .col-titles-sm-xl {
    ${tw``}
  }

  h2 {
    ${tw`font-fb text-2xl mb-0`}
    line-height: 44px;
    letter-spacing: -0.64px;

    @media (min-width: 768px) {
      font-size: 32px;
    }
  }

  p {
    ${tw`font-medium mb-0 mt-12`}
  }
`

const SectionTitles = props => {
  return (
    <SectionTitlesSection
      hideForSmall={props.hideForSmall}
      hideForTab={props.hideForTab}
      hideForLarge={props.hideForLarge}
      centerTitlesTab={props.centerTitlesTab}
      maxWidth={props.maxWidth}
      centerTitles={props.centerTitles}
      className="section-titles"
    >
      <div className="col-titles-sm-xl">
        {props.subTitle && <h5>{props.subTitle}</h5>}
        {props.mainTitle && (
          <h2 className="xs:h2-sm lg:h2-lg">{props.mainTitle}</h2>
        )}
        {props.description && <p> {props.description} </p>}
      </div>
    </SectionTitlesSection>
  )
}

SectionTitles.propTypes = {
  hideForSmall: PropTypes.bool,
  hideForTab: PropTypes.bool,
  hideForLarge: PropTypes.bool,
  subTitle: PropTypes.string,
  mainTitle: PropTypes.string,
  description: PropTypes.string,
  centerTitles: PropTypes.bool,
  centerTitlesTab: PropTypes.bool,
  maxWidth: PropTypes.bool,
}

SectionTitles.defaultProps = {
  hideForSmall: false,
  hideForTab: false,
  hideForLarge: false,
  centerTitles: true,
  centerTitlesTab: true,
  maxWidth: true,
}

export default SectionTitles
