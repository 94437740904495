import React from "react"
import styled from "styled-components"
import tw from "tailwind.macro"
import BackgroundImage from "gatsby-background-image"
import { Tab, Tabs as ReactTabs, TabList, TabPanel } from "react-tabs"
import Link from "../Link"
import SectionTitles from "../SectionTitles"

const TabSection = styled.section`
  ${tw`max-w-xl mx-auto px-18 md:px-60`}

  .react-tabs {
    ${tw`hidden md:block pt-26 xl:pt-40`}
  }

  .react-tabs__tab-list {
    ${tw`flex justify-between`}

    li {
      &.react-tabs__tab {
        ${tw`font-medium text-grey-light cursor-pointer text-center outline-none whitespace-no-wrap lg:text-xl`}
        line-height: 44px;
        border-radius: 0 15px;
        padding: 5px 20px;
        transition: all 200ms ease-in;

        @media (min-width: 1024px) {
          padding: 5px 40px;
        }
      }

      &.react-tabs__tab--selected {
        ${tw`bg-grey text-black-dark`}
      }
    }
  }

  .tab-panel-content {
    ${tw`flex pt-30 xl:pt-50`}

    .left-col {
      ${tw`w-2/4 xl:w-2/5`}
    }

    .right-col {
      ${tw`w-2/4 pl-9 lg:pl-36 xl:w-3/5 xl:pl-6 text-black-dark`}
    }

    .left-col-bg {
      ${tw`bg-no-repeat bg-cover bg-center h-full md:mt-5`}
      border-radius: 0 110px;
      min-height: 325px;

      @media (min-width: 1280px) {
        max-width: 325px;
        max-height: 325px;
        ${tw`mr-auto`}
      }

      ::after,
      ::before {
        border-radius: 0 110px;
      }
    }

    p {
      ${tw`text-black-dark leading-8 mb-14`}

      &:last-child {
        ${tw`mb-0`}
      }
    }
  }
`

const MobileTabItemListing = styled.div`
  ${tw`pt-30 md:hidden`}

  .mobile-tab-item {
    ${tw`mb-50`}

    &:last-child {
      ${tw`mb-0`}
    }
  }

  h5 {
    ${tw`font-medium text-black-dark bg-grey text-center mx-auto`}
    line-height: 44px;
    border-radius: 0 15px;
    max-width: 223px;
  }

  .mobile-image-wrap {
    height: 390px;
    ${tw`mt-32`}
  }

  .mobile-image-bg {
    ${tw`bg-no-repeat bg-cover bg-center h-full`}
    border-radius: 0 110px;

    ::after,
    ::before {
      border-radius: 0 110px;
    }
  }

  .content {
    ${tw`mt-30 text-black-dark leading-8`}
  }

  p {
    ${tw`text-black-dark leading-8 mb-14`}

    &:last-child {
      ${tw`mb-0`}
    }
  }
`

const Tabs = ({ data, subTitle, mainTitle, description }) => {
  return (
    <TabSection className="section-gap-sm xl:section-gap-xl">
      <SectionTitles
        subTitle={subTitle}
        mainTitle={mainTitle}
        description={description}
      />
      <MobileTabItemListing>
        {data.map((tabmobile, index) => (
          <div key={index} className="mobile-tab-item">
            <h5>{tabmobile.tabTitle}</h5>
            <div className="mobile-image-wrap">
              <BackgroundImage
                tag="div"
                className="mobile-image-bg"
                fluid={tabmobile.tabImage.localFile.childImageSharp.fluid}
              />
            </div>
            <div className="content">
              <div dangerouslySetInnerHTML={{ __html: tabmobile.tabContent }} />
              {tabmobile.tabButton && (
                <Link
                  className="btn-secondary btn-sm mt-12"
                  to={tabmobile.tabButton.target}
                  target={tabmobile.tabButton.target}
                >
                  {tabmobile.tabButton.title}
                </Link>
              )}
            </div>
          </div>
        ))}
      </MobileTabItemListing>

      <ReactTabs>
        <TabList>
          {data.map((tablist, index) => (
            <Tab key={index}>{tablist.tabTitle}</Tab>
          ))}
        </TabList>

        {data.map((tabpanel, index) => (
          <TabPanel key={index}>
            <div className="tab-panel-content">
              <div className="left-col">
                <BackgroundImage
                  tag="div"
                  className="left-col-bg"
                  fluid={tabpanel.tabImage.localFile.childImageSharp.fluid}
                />
              </div>
              <div className="right-col">
                <div
                  dangerouslySetInnerHTML={{ __html: tabpanel.tabContent }}
                />
                {tabpanel.tabButton && (
                  <Link
                    className="btn-secondary btn-sm mt-12 xl:mt-22"
                    to={tabpanel.tabButton.target}
                    target={tabpanel.tabButton.target}
                  >
                    {tabpanel.tabButton.title}
                  </Link>
                )}
              </div>
            </div>
          </TabPanel>
        ))}
      </ReactTabs>
    </TabSection>
  )
}

export default Tabs
